<!-- 考场统计表详情 -->
<template>
    <div class="statisticsDetails">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">考场统计表详情</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!--统计表 表格   -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650" id="out-table">
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="certificate_no" label="准考证号" align="center" width="180">
            </el-table-column>
            <el-table-column prop="username" label="学员姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="pinyin_name" label="拼音名" align="center" width="150">
            </el-table-column>
            <el-table-column prop="gender" label="性别" align="center" width="50">
            </el-table-column>
            <el-table-column prop="birthdate" label="出生日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="nation2" label="国籍" align="center" width="100">
            </el-table-column>
            <el-table-column prop="nation" label="民族" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course_name" label="学科" align="center" width="100">
            </el-table-column>
            <el-table-column prop="exam_level" label="等级" align="center" width="80">
            </el-table-column>
            <el-table-column prop="address" label="场地" align="center" width="200" 
                :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column prop="exam_date" label="考试日期" align="center" width="100">
            </el-table-column>
            <el-table-column prop="exam_time" label="考试时间" align="center" width="100">
            </el-table-column>
            <el-table-column prop="org_name" label="机构名称" align="center" width="200">
            </el-table-column>
            <el-table-column prop="certificatecopy_price" label="副证费" align="center" width="100">
            </el-table-column>
            <el-table-column prop="status2" label="安排状态" align="center" width="100">
            </el-table-column>
            <el-table-column prop="exam_status" label="考试状态" align="center" width="100">
            </el-table-column>
            <el-table-column prop="remark" label="说明" align="center" width="200">
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange"
         @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: [{
                    date: '2016-05-02',
                    address: '上海市-普陀区-金沙江'
                }],
                currentPage: 1,
                currentLimit: 20, //条数
                total: 0,
            }
        },
        created() {
            this.getList()
        },
        methods: {
            // 获取列表
            getList(){
                this.$request({
                    url:'/api/examarrange/student',
                    method:'POST',
                    data:{
                        id:this.$route.query.id,
                        page:this.currentPage,
                        limit:this.currentLimit
                    }
                }).then(res=>{
                    if(res.code==1){
                        console.log(res)
                        this.tableData=res.data.list
                        this.total=res.data.total
                    }
                })
            },
            
            goBack() { //返回
                this.$router.go(-1);
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            }
        }
    }
</script>

<style scoped="scoped">
    .statisticsDetails {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 30px 28px;
        display: flex;
        justify-content: space-between;
    }

    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }

    /* 表格 */
    .el-table {
        margin: 20px;
    }

    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }

    /* 查看更多 按钮 */
    .checkBtn {
        color: #FFFFFF;
        background: #18BC9C;
        font-size: 12px;
        border-radius: 2px;
        border: none;
    }

    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top:0px;
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
